<template>
  <v-dialog rounded="0" v-model="dialog" scrollable max-width="520" @keydown.esc="cancel">
    <v-card :class="$vuetify.theme.dark ? 'primary lighten-1' : 'primary lighten-3'">
      <v-card-title class="d-flex justify-space-between align-center pa-6 pb-0">
        <div class="text-h5 font-weight-bold">Your Wallet Address</div>
        <v-btn icon @click="cancel" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-6 pb-0">
        <v-sheet rounded="xl" outlined class="pl-4 pr-2" style="background: transparent !important">
          <v-text-field
            hide-details
            flat
            solo
            single-line
            v-model="inputAddress"
            @input="error = ''"
            placeholder="Insert Address Here"
            class="input no-padding-text-field transparent-text-field"
          >
            <template slot="append">
              <v-btn icon rounded outlined depressed color="primary" @click="add" class="px-8"
                ><v-icon large>mdi-plus</v-icon></v-btn
              >
            </template>
          </v-text-field>
        </v-sheet>
        <div class="error--text caption" style="height: 24px">{{ error }}</div>
        <div v-for="(item, index) in addresses" :key="item" class="d-flex justify-space-between align-center">
          <div class="text-truncate d-flex">
            <div class="font-weight-medium mr-2">{{ index + 1 }}.</div>
            <div>{{ item }}</div>
          </div>
          <v-btn icon @click="remove(item)"
            ><v-img
              :src="require(`@/assets/icons/delete-circle-icon.${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
              max-width="20px"
              max-height="20px"
              contain
            ></v-img
          ></v-btn>
        </div>
        <div class="d-flex justify-space-between py-6">
          <v-btn class="text-none btn-text py-5" rounded depressed @click="cancel">Cancel</v-btn>
          <v-btn rounded depressed class="text-none btn-text primary py-5" @click="ok">Done</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { localdata } from '@/helpers/local-data'
import web3 from 'web3'
import { walletStore } from '@/stores/wallet-store'
import { PublicKey } from '@solana/web3.js'
export default {
  data() {
    return {
      dialog: false,
      list: ['ETH', 'BNB', 'BUSD', 'LP3', 'WBNB'],
      resolve: null,
      inputAddress: '',
      error: '',
      addresses: [],
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.addresses = localdata.getWalletAddress(walletStore.account)
      return new Promise((r) => (this.resolve = r))
    },
    ok() {
      this.dialog = false
      this.inputAddress = ''
      this.resolve([...this.addresses])
    },
    cancel() {
      this.dialog = false
      this.inputAddress = ''
      this.resolve(null)
      this.addresses = localdata.getWalletAddress(walletStore.account)
    },
    add() {
      if (web3.utils.isAddress(this.inputAddress)) {
        const address = web3.utils.toChecksumAddress(this.inputAddress)
        if (this.addresses.includes(address)) {
          this.error = 'This address has already been added'
        } else {
          this.addresses = [...this.addresses, address]
          this.error = ''
        }
      } else {
        try {
          const acc = new PublicKey(this.inputAddress)
          if (this.addresses.includes(acc.toString())) {
            this.error = 'This address has already been added'
          } else {
            this.addresses = [...this.addresses, acc.toString()]
            this.error = ''
          }
        } catch (error) {
          this.error = 'This is not valid address'
        }
      }
    },
    remove(item) {
      this.addresses = this.addresses.filter((a) => a !== item)
    },
  },
}
</script>

<style scoped>
.card-dark {
  background: red;
}
</style>
